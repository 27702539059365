"use strict";

import axios from 'axios';
import config from '@/mixins/config.js';

const result = '{"s3": {"bucket": "bt-compare","folder": "uploads"},"accessId": "123","encodedPolicy": "456","encodedSignature": "789"}';

export default {
	s3: function(){
		if( config.methods.configIsLocal() ){
			return Promise.resolve( {
				ok: true,
				data: result
			} );
		}

		return axios.get('/api/upload')
		.then(function(result){
			return result.data
		})
	}
}