export default {
	methods: {
		configIsLocal() {
			return window.location.host.indexOf('local') > -1 ||
				window.location.host.indexOf('192.') > -1 ||
				window.location.host.indexOf('127.0.0.1') > -1 ;
		},
		configSitekey(){
			return '6Lf3NWkhAAAAAJHgyZvWwY4Yk4fwBXFl8HkJqca1'
		},
		configEmployeeOptions(){
			return [
				{value:"1-9",label:"1‐9"},
				{value:"10-49",label:"10‐49"},
				{value:"50-249",label:"50‐249"},
				{value:"250+",label:"250+"}
			];
		},
		configRegionsTreeData(){
			return [
				{
					id:'all',
					text: 'UK Wide',
					state: { expanded: true },
					children: [
						{
							id:'england',
							text:'England',
							state: { expanded: true },
							children:[
								{id:'england-em',text:'East Midlands'},
								{id:'england-el',text:'East London'},
								{id:'england-nw',text:'North West'},
								{id:'england-ne',text:'North East'},
								{id:'england-se',text:'South East'},
								{id:'england-sw',text:'South West'},
								{id:'england-wm',text:'West Midlands'},
								{id:'england-y',text:'Yorkshire and The Humber'},
							]
						},
						{
							id:'scotland',
							text:'Scotland',
							state: { expanded: true },
							children:[
								{id:'scotland-hi',text:'Highlands and Islands'},
								{id:'scotland-rest',text:'Rest of Scotland'}
							]
						},
						{				
							id:'ni',
							text:'Northern Ireland'
						},
						{				
							id:'wales',
							text:'Wales'
						}
					]
				}
			]
		},
		configSelectTierOptions(){
			return [
				{value:"0",label:"New Partner",image: 'https://bt-compare.s3.amazonaws.com/website/logo/BT_Wholesale_New_Partner_RGB.png'},
				{value:"1",label:"Partner",image: 'https://bt-compare.s3.amazonaws.com/website/logo/BT-Wholesale_Partner_RGB.png'},
				{value:"2",label:"Premium Partner",image: 'https://bt-compare.s3.amazonaws.com/website/logo/BT_Wholesale_Premium_Partner_RGB.png'},
				{value:"3",label:"Elite Partner",image: 'https://bt-compare.s3.amazonaws.com/website/logo/BT-Wholesale_Elite-Partner_RGB.png'}
			];
		},
		getTierInfoForValue(tier){
			var tr = this.configSelectTierOptions().filter(function(ele){ 
				return ele.value == tier })[0];
			return tr;
		},
		getRegionById(region_id){
			var tr = this.configRegionsTreeData();

			function recursiveTreeLookup(id, arr) {
				for (var i = 0; i < arr.length; i++) {
					if( arr[i].id == id ) { // if region_id matches id return text
						return arr[i];
					} else if ( 
						id.split('-')[0] == arr[i].id &&
						typeof arr[i].children != 'undefined' 
					) { 
						return recursiveTreeLookup(id, arr[i].children);
					}
				}
			}

			if (region_id == tr[0].id) {
				return tr[0];
			} else {
				return recursiveTreeLookup(region_id, tr[0].children);
			}
		},
		getGroupedRegions(regions_arr){
			var tr = this.configRegionsTreeData();
			var ret = [];

			if( regions_arr.indexOf(tr[0].id) > -1 ) { // if 'all' then return only that
				return [tr[0].id];
			}

			for (var i = 0; i < tr[0].children.length; i++) { // if country level add that to ret
				if( regions_arr.indexOf( tr[0].children[i].id ) > -1 ) {
					ret.push(tr[0].children[i].id);
				}
			}

			for (var k = 0; k < regions_arr.length; k++) {
				if( ret.indexOf( regions_arr[k].split('-')[0] ) == -1 ) {
					ret.push(regions_arr[k]);
				}
			}

			return ret;

		},
		getAllRegionsFlat(){
			var all_regions = this.configRegionsTreeData();
			var flat_regions = [];

			function recursiveTreeLookup(arr) {
				for (var i = 0; i < arr.length; i++) {
					flat_regions.push(arr[i].id);
					if(typeof arr[i].children != 'undefined' && arr[i].children.length > 0) {
						recursiveTreeLookup(arr[i].children);
					}
				}
			}

			recursiveTreeLookup(all_regions);

			return flat_regions;
		},
		configChartColors() {
			return [
				'#5514b4',
				'#6F2ECE',
				'#8847E7',
				'#A261FF',
				'#BB7AFF',
				'#D493FF',
				'#EEADFF',
				'#FFC7FF',
				'#FFE0FF',
				'#00aadc',
				'#0096c8',
				'#0082b4',
				'#006ea0',
				'#005a8c',
				'#004678',
				'#003c6e'
			]
		},
		configSiteOptions(){
			return [
				{value:"is_r2r",label:"R2R"},
				{value:"is_reseller",label:"Reseller"},
				{value:"all",label:"All"},
			];
		}
	}
}