export const supplierData = [
	{
		name:'Supplier B',email:'SupplierB@example.com',id:1,suppliers_id:'bbb',
		attributes: [{attributes_id:'bbb',name:'Collaboration Tools'}],
		is_promoted  : false,
		promoted  : [],
		approved  : false,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://b.com',
		description:'Some<br>text',
		employees:'250+',
		regions_list:'all,england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y,scotland,scotland-hi,scotland-rest,ni,wales',
		tier: '0',
		is_r2r: true,
		is_reseller: true
	},
	{
		name:'Supplier A',email:'SupplierA@example.com',id:2,suppliers_id:'aaaa',
		attributes: [{attributes_id:'ggg',name:'Mobile'},{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted  : true,
		promoted  : [{attributes_id:'ggg',name:'Mobile'}],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://a.com',
		description:'Some<br>more<br>text',
		employees:'10-49',
		regions_list:'scotland-hi,england-nw',
		tier: '1',
		is_r2r: true,
		is_reseller: false
	},
	{
		name:'Another supplier',email:'supplier@foo.info',id:3,suppliers_id:'zzzz',
		attributes: [{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted : false,
		promoted  : [],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://goo.info',
		description:'More<br>text',
		message:'I wrote this',
		employees:'1-9',
		regions_list:'england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y',
		tier:'2',
		is_r2r: false,
		is_reseller: true
	},
	
	{
		name:'Supplier C',email:'SupplierB@example.com',id:4,suppliers_id:'bbb2',
		attributes: [{attributes_id:'bbb',name:'Collaboration Tools'}],
		is_promoted  : false,
		promoted  : [],
		approved  : false,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://b.com',
		description:'Some<br>text',
		employees:'250+',
		regions_list:'all,england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y,scotland,scotland-hi,scotland-rest,ni,wales',
		tier: '0',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Supplier D',email:'SupplierA@example.com',id:5,suppliers_id:'aaaa2',
		attributes: [{attributes_id:'ggg',name:'Mobile'},{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted  : true,
		promoted  : [{attributes_id:'ggg',name:'Mobile'}],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://a.com',
		description:'Some<br>more<br>text',
		employees:'10-49',
		regions_list:'scotland-hi,england-nw',
		tier: '1',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Another supplier 2',email:'supplier@foo.info',id:6,suppliers_id:'zzzz2',
		attributes: [{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted : false,
		promoted  : [],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://goo.info',
		description:'More<br>text',
		message:'I wrote this',
		employees:'1-9',
		regions_list:'england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y',
		tier:'2',
		is_r2r: false,
		is_reseller: true
	},

	{
		name:'Supplier E',email:'SupplierB@example.com',id:7,suppliers_id:'bbb3',
		attributes: [{attributes_id:'bbb',name:'Collaboration Tools'}],
		is_promoted  : false,
		promoted  : [],
		approved  : false,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://b.com',
		description:'Some<br>text',
		employees:'250+',
		regions_list:'all,england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y,scotland,scotland-hi,scotland-rest,ni,wales',
		tier: '0',
		is_r2r: true,
		is_reseller: false
	},
	{
		name:'Supplier F',email:'SupplierA@example.com',id:8,suppliers_id:'aaaa3',
		attributes: [{attributes_id:'ggg',name:'Mobile'},{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted  : true,
		promoted  : [{attributes_id:'ggg',name:'Mobile'}],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://a.com',
		description:'Some<br>more<br>text',
		employees:'10-49',
		regions_list:'scotland-hi,england-nw',
		tier: '1',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Another supplier 3',email:'supplier@foo.info',id:9,suppliers_id:'zzzz3',
		attributes: [{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted : false,
		promoted  : [],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://goo.info',
		description:'More<br>text',
		message:'I wrote this',
		employees:'1-9',
		regions_list:'england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y',
		tier:'2',
		is_r2r: false,
		is_reseller: true
	},

	{
		name:'Supplier G',email:'SupplierB@example.com',id:10,suppliers_id:'bbb4',
		attributes: [{attributes_id:'bbb',name:'Collaboration Tools'}],
		is_promoted  : false,
		promoted  : [],
		approved  : false,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://b.com',
		description:'Some<br>text',
		employees:'250+',
		regions_list:'all,england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y,scotland,scotland-hi,scotland-rest,ni,wales',
		tier: '0',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Supplier H',email:'SupplierA@example.com',id:11,suppliers_id:'aaaa4',
		attributes: [{attributes_id:'ggg',name:'Mobile'},{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted  : true,
		promoted  : [{attributes_id:'ggg',name:'Mobile'}],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://a.com',
		description:'Some<br>more<br>text',
		employees:'10-49',
		regions_list:'scotland-hi,england-nw',
		tier: '1',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Another supplier 4',email:'supplier@foo.info',id:12,suppliers_id:'zzzz4',
		attributes: [{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted : false,
		promoted  : [],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://goo.info',
		description:'More<br>text',
		message:'I wrote this',
		employees:'1-9',
		regions_list:'england,england-em,england-el,england-nw,england-ne,england-se,england-sw,england-wm,england-y',
		tier:'2',
		is_r2r: false,
		is_reseller: true
	},
	{
		name:'Supplier latest',email:'SupplierA@example.com',id:9,suppliers_id:'hosteddddd',
		attributes: [{attributes_id:'ggg',name:'Mobile'},{attributes_id:'aaa',name:'Cloud Service'}],
		is_promoted  : true,
		promoted  : [{attributes_id:'ggg',name:'Mobile'}],
		approved  : true,
		image_url : 'https://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		image_path: 's3://img.freepik.com/free-vector/branding-identity-corporate-vector-logo-design_460848-8717.jpg?size=626&ext=jpg',
		name_account: '',
		website_url:'https://a.com',
		description:'Some<br>more<br>text',
		employees:'10-49',
		regions_list:'scotland-hi,england-nw',
		tier: '1',
		is_r2r: false,
		is_reseller: true
	}
];