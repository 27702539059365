var dictionary = {
    en: {
        attributes: {
            website_url: 'website url',
            image_path: 'image path',
            companyNameByTrade: 'company name',
            emailLeadsTo: 'email',
            companyDescription: 'company description',
            firstname: 'first name'
        },
        messages:{
            url: () => 'The website must be a valid URL e.g. https://www.example.com'
        }
    }
}

export default {
	methods: {
		dictionaryGetAll() {
			return dictionary;
		}
	}
}