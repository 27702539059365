"use strict";

import axios from 'axios';
import config from '@/mixins/config.js';
import {attributeData} from '/dummy-data/attributes.js';

export default {
  getAll: function(){
    if( config.methods.configIsLocal() ){
      var _attrs = attributeData.sort(function(a, b){ 
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
      return Promise.resolve({
        rows:_attrs,
        total_count:attributeData.length
      });
    }

    return axios.get(
      '/api/attributes'
    ).then(function(result){ return result.data });
  },
  get: function(page,perPage,sort,sortDir){
    if( config.methods.configIsLocal() ){

      attributeData.sort(function(a,b){
        var p = 'name';
        var r=0;
        
        if(a[p]>b[p]) r = 1;
        if(a[p]<b[p]) r = -1;

        if( sortDir == 'desc' ){
          r=r*-1;
        }

        return r;
      })

      let start = (page - 1) * perPage;
      let end = page * perPage;

      return Promise.resolve({
        rows:attributeData.slice(start,end),
        total_count:attributeData.length
      });
    }

    return axios.get(
      '/api/attributes?page='+page+'&perPage='+perPage+'&sort='+sort+'&sortDir='+sortDir
    ).then(function(result){ return result.data });
  },
  add: function(name){
    if( config.methods.configIsLocal() ){
      const t = (new Date()).getTime();
      attributeData.push({attributes_id:t,name:name})
      return Promise.resolve( {attributes_id:t} );
    }

    return axios.post(
      '/api/attribute',{
        name:name,
      }).then(function(result){
        return result.data
      })
  },
  getByAttributeId: function(attributes_id){
    if( config.methods.configIsLocal() ){
      var attr = attributeData.filter(function(ele){ return ele.attributes_id == attributes_id })[0];
      return Promise.resolve(attr);
    }

    return axios.get(
      '/api/attribute/'+attributes_id
      ).then(function(result){
        return result.data
      });    
  },
  update: function(attributes_id,name){
    if( config.methods.configIsLocal() ){
      attributeData.map(function(ele){ if( ele.attributes_id == attributes_id ){
        ele.name=name 
      }});

      var attr = attributeData.filter(function(ele){ return ele.attributes_id == attributes_id })[0];
      return Promise.resolve(attr);
    }

    return axios.post(
      '/api/attribute/'+attributes_id,{
        name:name,
      }).then(function(result){
        return result.data
      })    
  },
  delete: function(attributes_id){
    if( config.methods.configIsLocal() ){
      for( var i=0;i < attributeData.length;i++ ){
        if( attributeData[i].attributes_id == attributes_id ){
          attributeData.splice(i,1);
        }
      }
      return Promise.resolve({ok:true})
    }

    return axios.delete(
      '/api/attribute/'+attributes_id
      ).then(function(result){
        return result.data
      });    
  },
  hasDuplicateName: function(name){
    if (config.methods.configIsLocal()) {
      var attr = attributeData.filter(function(ele){ return ele.name.toLowerCase() == name.toLowerCase() });
      if (attr.length > 0) {
        return Promise.resolve( {ok:true} );
      }
      return Promise.resolve( {ok:false} );
    }

    return axios.post(
      '/api/attributeduplicate',{
        name:name
      }).then(function(result){
        return result.data
      });
  }

}
