<template>
    <div class="plupload-container">
        <input type="button" class="plupload-btn is-button is-button__basic--primary" name="file" 
            :id="id" 
            :value="label" 
            :ref="id+'_btn'"
            v-show="!loading"
        >
        <p v-show="loading">Loading, please wait....</p>
        <span class="upload-file__indicator"></span>
    </div>
</template>

<script>
import Vue from 'vue';
import config from '@/mixins/config';

import uploadService from '@/services/uploadService.js';
    
export default{
    props:['label','options','id'],
    mixins: [config],
    data : function() {
        return {
            uploader    : null,
            siteKey     : '',
            loading     : false,
        }
    },
    methods: {
        initUploader: async function() {
            var self = this;
            this.loading = true;
            const ServiceFeedback = await uploadService.s3();

            Vue.nextTick(function () {

                self.loading = false;
                if( typeof ServiceFeedback.ok != 'undefined' && ServiceFeedback.ok ){
                    self.renderPlupload(JSON.parse(ServiceFeedback.data));
                    return
                }
            })
        },
        renderPlupload: function(data) {
            var self = this;

            var s3_bucket = data.s3.bucket;
            var s3_folder = data.s3.folder;

            var thumb_uploadOpt = {
                fileName: '',
                runtimes: "html5",
                url: 'https://'+s3_bucket+'.s3-eu-west-1.amazonaws.com/',

                file_data_name: "file",
                multipart: true,

                multipart_params: {
                    "acl": "private",
                    "success_action_status": "201",
                    "key": s3_folder+'/'+this.fileName,
                    "Filename": s3_folder+this.fileName,
                    "AWSAccessKeyId" : data.accessId,
                    "policy": data.encodedPolicy,
                    "signature": data.encodedSignature
                },
                
                max_file_size : "10mb",

                filters: {
                    max_file_size: '10mb',
                    mime_types : [
                        { title : "Image files", extensions : "jpg,gif,png,jpeg" },
                    ],
                },

                browse_button: self.$refs[self.id+'_btn']
            }

            self.uploader = new plupload.Uploader(thumb_uploadOpt);

            self.uploader.bind("PostInit", function(uploader){
                self.$emit("init", uploader);
            });
            
            self.uploader.bind("FilesAdded", function(up, files){
                self.$emit("added", up, files);
            });
            
            self.uploader.bind("UploadProgress", function(up, file){
                if( self.configIsLocal() ) { // if local, stop the process and remove the file, return
                    up.stop();
                    up.removeFile(file);
                    up.start();
                    self.$emit("cancel");
                    return;
                }
                self.$emit("progress", up, file);
            });
            
            self.uploader.bind("FileUploaded", function(up, file, response){                
                // When Amazon S3 returns a 201 reponse, it will provide
                // an XML document with the values related to the newly
                // uploaded Resource. This function extracts the two
                // values: Bucket and Key.
                function parseAmazonResponse( response ) {
                    if(typeof response === undefined) {
                        throw new Error('response is undefined');
                    }

                    var result = {};
                    var pattern = /<(Bucket|Key)>([^<]+)<\/\1>/gi;
                    var matches = null;
                    while ( (matches = pattern.exec( response )) ) {
                        var nodeName = matches[ 1 ].toLowerCase();
                        var nodeValue = matches[ 2 ];
                        result[ nodeName ] = nodeValue;
                    }
                    return( result );
                }
                var resourceData = parseAmazonResponse( response.response );

                // When passing the uploaded Key back to the
                // success page, we have to be sure to fullly
                // encode the key so that it doesn't confuse
                // any other parts of the normal URL component
                // system (ex. hashes in a filename can be
                // misinterpreted as the URL hash).

                self.$emit("uploaded", up, file, {
                    key         : resourceData.key,
                    file_size   : file.origSize,
                    bucket      : resourceData.bucket
                });                
            });
            
            self.uploader.bind("Error", function(up, err){
                self.$emit("error", up, err);
            });

            self.uploader.bind("BeforeUpload", function(uploader,file){
                // non-alphanumeric regex
                //var re = new RegExp( /[^a-zA-Z\d\s\-]/g )
                var re = new RegExp( /[^a-zA-Z\d\s-]/g )

                //console.log(re,file.name,re.test(file.name))
                // if file.name has non-alphas, return false
                if( re.test(file.name) ) {
                    // split file name by .
                    var name_split = file.name.split('.');
                    // save the ext
                    var ext        = name_split[name_split.length-1];
                    // pop the ext off the split arr
                    var name_part = name_split.slice(0,name_split.length-1).join('.');
                    // replace all the non-alphanumerics
                    //var replace = name_part.replace(/[^a-zA-Z\d\s\-]/g,'');
                    var replace = name_part.replace(/[^a-zA-Z\d\s-]/g,'');
                    // add the ext, update the file name
                    file.name  = replace+'.'+ext;
                }

                var uniqueKey = ( "uploads/" + file.id + "/" + file.name );

                // Update the Key and Filename so that Amazon S3
                // will store the resource with the correct value.
                uploader.settings.multipart_params.key = uniqueKey;
                uploader.settings.multipart_params.Filename = uniqueKey;
            });

            self.uploader.bind("QueueChanged", function(uploader){
                // I determine if the upload is currently inactive.
                function isNotUploading() {
                    var currentState = uploader.state;
                    return( currentState === plupload.STOPPED );
                }

                if ( uploader.files.length && isNotUploading() ){
                    uploader.start();
                }
            });

            self.uploader.init();
        }
    },

    mounted:function(){
        this.siteKey = this.configSitekey();

        this.initUploader();
    }
};
</script>

<style scoped>
    .plupload-container {
        margin: 5px 0 8px 0;
    }
</style>