export const attributeData = [
	{ 
		name:'Cloud Service',
		attributes_id:'aaa',
		id:1 
	},
	{ 
		name:'Collaboration Tools',
		attributes_id:'bbb',
		id:2 
	},
	{ 
		name:'Connectivity (Broadband / Fibre / Optical)',
		attributes_id:'ccc',
		id:3 
	},
	{ 
		name:'CyberSecurity',
		attributes_id:'ddd',
		id:4 },
	{ 
		name:'Hosted Communications',
		attributes_id:'eee',
		id:5 
	},
	{ 
		name:'Managed IT Support',
		attributes_id:'fff',
		id:6 
	},
	{ 
		name:'Mobile',
		attributes_id:'ggg',
		id:7 
	},
	{ 
		name:'Voice',
		attributes_id:'hhh',
		id:8 
	}
];